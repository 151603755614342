#exercisePaper {
    position: relative;
    margin-bottom: 30px;
    background-color: hsl(0, 0%, 97%);
}
#Schwierigkeit {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: red;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 15px;
    top: -30px;
}

.filterColumn {
    position:sticky;
    top:0;
}