.TabPill{
    font-size: 25px;
}

#TabPillWrapper{
    display:flex;
    justify-content:center;
    align-items:center;
    margin-bottom:5vh
}

@media screen and (max-width: 700px) {
    .TabPill {
        font-size: 15px;
    }}