#headline {
    font-weight: bold;
    color: white;
    font-size: 72px;
    margin: 0px;
    font-family: Arial, Helvetica, sans-serif;
}

.bigHeadline {
    font-weight: bold;
    font-size: 72px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

#subheadline {
    font-size: 22px;
    width:50%;
    margin-bottom: 100px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    text-align: justify;
}

.bigSubheadline {
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

@media only screen and (max-width: 800px) {
    #subheadline {
        width:100%;
    }
  }

@media screen and (max-width: 800px) {

    #headline {font-size: 42px;}
    .bigHeadline {font-size: 42px;}

}


