#greetingImage {
    position: absolute;
    width:100%;
    height:120vh;
    background-image:url("https://images.unsplash.com/photo-1577962144759-8dec6b55c952?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80"); 
    background-size: cover;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
    z-index: -1;
}

#old_images {
    background-image:url("https://images.unsplash.com/photo-1660699059422-c94967288c11?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"); 
}

#secondImage {
    position: absolute;
    bottom: 0px;
    background-image:url("https://images.unsplash.com/photo-1577962144759-8dec6b55c952?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80"); 
    z-index: -1;
    height: 120vh;
    width:100%;
    background-size: cover;
    mask-image: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 1.0) 50%, transparent 100%);
}